<template>
  <div>
    <div class="page-wrapper chiller-theme">
      <main class="page-content">
        <div class="container-fluid">
          <div class="row bottom_header w-100 mx-0 mt-4">
            <div class="w-100 p-0">
              <div class="container-fluid tab-panel p-0">
                <div class="card w-100">
                  <div class="card-body p-0">
                    <div>
                      <w-chartoverview
                        :chartSummary="salseStatics"
                      ></w-chartoverview>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- Marketing RIO -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3 graph-section">
                <div class="card w-100 ">
                  <div class="card-body row">
                    <div class="col-6 fit-content border_right">
                      <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Marketing ROI
                      </div>
                      <w-columnchart :chartData="marketingRio" />
                    </div>
                    <div class="col-6 row">
                      <div class="col-6 fit-content">
                        <div
                          class="
                            card-header
                            font-large
                            ProximaNovaBold
                            text-center
                          "
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          Short term ROI
                        </div>
                        <pie-chart :series="series" />
                      </div>
                      <div class="col-6 fit-content">
                        <div
                          class="
                            card-header
                            font-large
                            ProximaNovaBold
                            text-center
                          "
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          Long term ROI
                        </div>
                        <pie-chart :series="series" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Investment vs RIO -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-12 fit-content">
                      <!-- <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Investment vs ROI
                      </div>
                      <w-columnchart :chartData="investRio" />-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Digital -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-12 fit-content">
                      <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Digital
                      </div>
                      <button
                        class="hide-breakdown btn-default float-right"
                        @click="digitalBreakdown = !digitalBreakdown"
                      >
                        <i
                          class="fas fa-eye"
                          style="align-items: left"
                          v-if="digitalBreakdown"
                        ></i>
                        <img
                          src="../../assets/Icons/hide-password.svg"
                          alt=""
                          v-if="!digitalBreakdown"
                        />
                        Breakdown
                      </button>
                      <w-columnchart :chartData="digital" />
                      <div v-if="digitalBreakdown" class="border_top_divider">
                        <div
                          class="card-header font-large ProximaNovaBold"
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          {{ digitalTitle.text }}

                          <div
                            class="
                              dropdown-breakdown
                              float-right
                              position-relative
                            "
                          >
                            <w-dropdown
                              :options="digitaldropdown"
                              :placeHolder="'Select'"
                              class="mr-3"
                              dropdownWidth="100%"
                              bgColor="#fff"
                              labelColor="#727988"
                              boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
                              :selectedOption="digitaldropdown[0]"
                              @input="selectedDigitalDropdown($event)"
                            ></w-dropdown>
                          </div>
                        </div>
                        <w-columnchart :chartData="digitalMedia" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Content -->
            <div class="w-100">
              <div class="container-fluid tab-panel mt-5 px-3">
                <div class="card w-100">
                  <div class="card-body row">
                    <div class="col-12 fit-content">
                      <div
                        class="card-header font-large ProximaNovaBold"
                        style="
                          padding: 1.5rem 1.5rem;
                          background: transparent;
                          border: 0px !important;
                        "
                      >
                        Content
                      </div>
                      <button
                        class="hide-breakdown btn-default float-right"
                        @click="contentBreakdown = !contentBreakdown"
                      >
                        <i
                          class="fas fa-eye"
                          style="align-items: left"
                          v-if="contentBreakdown"
                        ></i>
                        <img
                          src="../../assets/Icons/hide-password.svg"
                          alt=""
                          v-if="!contentBreakdown"
                        />
                        Breakdown
                      </button>
                      <w-columnchart :chartData="content" />
                      <div v-if="contentBreakdown" class="border_top_divider">
                        <div
                          class="card-header font-large ProximaNovaBold"
                          style="
                            padding: 1.5rem 1.5rem;
                            background: transparent;
                            border: 0px !important;
                          "
                        >
                          {{ contentTitle.text }}

                          <div
                            class="
                              dropdown-breakdown
                              float-right
                              position-relative
                            "
                          >
                            <w-dropdown
                              :options="contentdropdown"
                              :placeHolder="'Select'"
                              class="mr-3"
                              dropdownWidth="100%"
                              bgColor="#fff"
                              :selectedOption="contentdropdown[0]"
                              labelColor="#727988"
                              boxShadow="box-shadow: 0 0 8px 0 #c9ced5;"
                              @input="selectedContentDropdown($event)"
                            ></w-dropdown>
                          </div>
                        </div>
                        <w-columnchart :chartData="digitalMedia" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import PieChart from "@/components/Chart/PieChart.vue";

export default {
  name: "Summary",
  components: {
    "w-dropdown": Dropdown,
    // "w-tab": Tab,
    "w-chartoverview": ChartOverview,
    "w-columnchart": ColumnChart,
    PieChart,
  },
  data() {
    return {
      digitalBreakdown: false,
      contentBreakdown: false,
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: [
            {
              name: "Content",
              y: 23,
              color: "#ffdd7c",
            },
            {
              name: "Digital",
              y: 77,
              sliced: true,
              selected: true,
              color: "#faacfe",
            },
          ],
        },
      ],
      marketTypedropdown: [
        { text: "USA", id: "1" },
        { text: "INDIA", id: "2" },
        { text: "UK", id: "3" },
      ],
      digitalTitle: "",
      digitaldropdown: [
        {
          text: "Linkedin",
          id: "1",
          iconHTML: '<i class="fab fa-linkedin"></i>',
        },
        {
          text: "Facebook",
          id: "2",
          iconHTML: '<i class="fab fa-facebook"></i>',
        },
        {
          text: "Instagram",
          id: "3",
          iconHTML: '<i class="fab fa-instagram"></i>',
        },
        {
          text: "Twitter",
          id: "4",
          iconHTML: '<i class="fab fa-twitter"></i>',
        },
      ],
      contentTitle: "",
      contentdropdown: [
        { text: "Owned content", id: "1" },
        { text: "Paid Media", id: "2" },
      ],
      activeTab: "ROI summary",
      tabs: [
        {
          category: "Sales Insights",
        },
        {
          category: "Future Planning",
        },
        {
          category: "ROI summary",
        },
      ],
      activeFrom: 1,
      salseStatics: [],
      marketingRio: {
        data: [
          {
            name: "Base Sales",
            data: [0, 360],
          },
          {
            name: "Short Term ROI",
            data: [200, 240],
          },
          {
            name: "Long Term ROI",
            data: [0, 240],
          },
        ],
        color: ["#67e8ff", "#b5a9ff", "#ffdd7c"],
        title: {},
        subtitle: {},
        legend: {},
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: {
            enabled: true,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            groupPadding: 0.4,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
            headerFormat:
              `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
            pointFormat:
              '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
              '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
            shadow: false,
            style: {
              padding: 0,
            },
          },
      },
      investRio: {
        data: [
          {
            name: "Investment",
            data: [30, 60, 30, 12, 30, 43, 50],
          },
          {
            name: "ROI",
            data: [60, 15, 50, 50, 20, 30, 45],
          },
        ],
        categories: [
          "HSM",
          "AP/TS",
          "Karnataka",
          "Tamil Nadu",
          "Maharashtra",
          "Madhya Pradesh",
          "Gujarat",
        ],
        color: ["#67e8ff", "#ffdd7c"],
        title: {
          text: "Investment vs ROI",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
            headerFormat:
              `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
            pointFormat:
              '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
              '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
            shadow: false,
            style: {
              padding: 0,
            },
          },
      },
      digital: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30, 10, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50, 20, 50],
          },
        ],
        color: ["#b5a9ff", "#67e8ff"],
        title: {},
        subtitle: {},
        legend: {},
        xAxis: {
          categories: [
            "Google Ads",
            "Facebook",
            "Programmatic",
            "Publishers",
            "Instagram",
            "Twitter",
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {            
            pointPadding: 0.2,
            borderWidth: 0,
            groupPadding: 0.4,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
            headerFormat:
              `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
            pointFormat:
              '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
              '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
            shadow: false,
            style: {
              padding: 0,
            },
          },
      },
      content: {
        data: [
          {
            name: "Investment",
            data: [30, 43],
          },
          {
            name: "ROI",
            data: [48, 12],
          },
        ],
        categories: ["Paid Media", "Owned Content"],
        color: ["#b5a9ff", "#ffdd7c"],
        title: {},
        subtitle: {},
        legend: { enabled: true},
        xAxis: {
          categories: ["Paid Media", "Owned Content"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            groupPadding: 0.46,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },          
        },        
        tooltip: {
            headerFormat:
              `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
            pointFormat:
              '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
              '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
            shadow: false,
            style: {
              padding: 0,
            },
        },
      },
      digitalMedia: {
        data: [
          {
            name: "Investment",
            data: [30, 10, 60, 30],
          },
          {
            name: "ROI",
            data: [50, 30, 50, 50],
          },
        ],
        color: ["#ffa2a2", "#faacfe"],
        xAxis: {
          categories: ["Instagram", "Facebook", "Youtube", "Twitter"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Growth",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        tooltip: {
            headerFormat:
              `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
            pointFormat:
              '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
              '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
            shadow: false,
            style: {
              padding: 0,
            },
          },
      },
      classess: [
        "blue-pastel",
        "green-pastel",
        "pink-pastel",
        "red-pastel",
        "yellow-pastel",
        "purple-pastel",
        "green-pastel",
      ],
    };
  },
  created() {
    this.salseStatics = [];
    var statics = JSON.parse(sessionStorage.getItem("selectedPlan"));
    this.selectedstat = { text: statics.title, id: statics.title };
    for (var i = 0; i < statics.statics.length; i++) {
      this.salseStatics.push({
        name: statics.statics[i].name,
        value: statics.statics[i].value,
        className: this.classess[i],
      });
    }
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    selectedDigitalDropdown(e) {
      this.digitalTitle = e;
    },
    selectedContentDropdown(e) {
      this.contentTitle = e;
    },
  },
};
</script>

<style scoped>
.card_body_padding {
  padding: 30px 24px !important;
}
.fit-content {
  height: fit-content;
}
.hide-breakdown {
  width: 150px;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background: #f3f3f3;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 45px;
}
.border_right {
  border-right: 1px solid #ccc;
}
.border_right::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -11px;
  width: 21px;
  height: 20px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(134deg);
}
.border_top_divider {
  border-top: 1px solid #ccc;
}
.border_top_divider::after {
  content: "";
  display: block;
  position: absolute;
  top: 47.8%;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-right: 1px solid #cad5e0;
  border-bottom: 1px solid #cad5e0;
  transform: rotate(224deg);
  left: 25%;
}
.dropdown-breakdown {
  width: 210px;
  object-fit: contain;
  font-size: 15px;
  position: absolute;
  right: 15px;
}
.bottom_header {
  position: sticky;
  top: 80px;
  z-index: 8;
  background: #f2f5fa;
  box-shadow: rgb(233 235 239) 0px 5px 5px 0px;
}
.graph-section {
    margin-top: 125px !important;
}
/deep/text.highcharts-title {
    display: none;
}
</style>
