<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="d-block d-md-flex justify-content-between">
        <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
          <li>
            <router-link to="/solutions/results">
              <span>Model results</span>
            </router-link>
          </li>
          <li>
            <router-link to="/solutions/stats">
              <span>Sale Statistics</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Sales - Insights</li>
        </ul>
        <div>
          <div class="" style="height: 40%">
            <div class="w-100 p-4 pr-5">
              <w-white-dropdown
                :options="marketDropdown"
                :placeHolder="'Select'"
                :selectedOption="selectedMarket"
                :labelText="'Market type'"
                class="mr-3"
                @input="selectMarket($event)"
              ></w-white-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid mt-5">
      <w-tab
        :tabName="activeTab"
        :tabs="tabs"
        @activeTab="changeTab($event)"
      >
      </w-tab>
      <div v-if="activeTab === 'Sales Insights'">
        <div class="mt-5" :class="breakDown ? 'card' : ''">
          <b-row class="no-gutters">
            <b-col lg="4" :class="breakDown ? '' : 'card'">
              <div>
                <pie-chart
                  title="Overall"
                  :series="overallSeries"
                  :colors="overAllPieColor"
                  :showBreakdown="true"
                  :breakdown="breakDown"
                  @getBreakdown="getBreakdown"
                />
              </div>
            </b-col>
            <b-col lg="8" v-if="breakDown" class="breakdownable-card">
              <multiple-donut-chart
                :series1="externalData"
                :series2="internalData"
                :title="multipleTitle"
              />
            </b-col>
          </b-row>
        </div>
        <div class="card mt-5 p-5">
          <b-container fluid>
            <Digital :data="digitalExpandData" :stripData="digitalData" @getDataBreakdown="getDataBreakdown" />
          </b-container>
        </div>
        <div class="card mt-5 p-5">
          <b-container fluid>
            <Content :data="contentExpandData" :stripData="contentData" />
          </b-container>
        </div>
      </div>
      <w-summary v-if="activeTab === 'ROI Summary'"></w-summary>
      <w-future-planning
        v-if="activeTab === 'Future Planning'"
      ></w-future-planning>
    </div>
  </div>
</template>
<script>
import Tab from "@/components/Solutions/Tab.vue";
import PieChart from "../Chart/PieChart.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
// import ROISummary from "@/pages/Rio-Summary/Summary.vue";
import ROISummary from "@/pages/Roi-Summary/Summary.vue";
import FuturePlanning from "../../pages/AIMO-AI/FuturePlanning.vue";

export default {
  name: "Insights",
  components: {
    "w-summary": ROISummary,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    "w-future-planning": FuturePlanning,
    PieChart,
    Digital,
    Content,
    MultipleDonutChart,
  },
  data() {
    
    return {
      overAllPieColor:["#64ffda","#ff4081"],
      breakDown: false,
      contentBreakDown: false,
      activeTab: "Sales Insights",
      activeFrom: 1,
      selectedMarket: "",
      tabs: [
        {
          category: "Sales Insights",
        },
        {
          category: "Future Planning",
        },
        {
          category: "ROI Summary",
        },
      ],
      marketDropdown: [
        { text: "All India", id: "allindia" },
        { text: "USA", id: "USA" },
        { text: "HSM", id: "hsm", disable: true },
        { text: "KER", id: "ker", disable: true },
        { text: "KA", id: "KA", disable: true },
      ],
      overallSeries: [
        {
          name: "Overall",
          colorByPoint: true,
          data: [
            {
              name: "Internal",
              y: 23,
              sliced: true,
              selected: true,
            },
            {
              name: "External",
              y: 77,
            },
          ],
        },
      ],
      digitalData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 61,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 39,
          backgroundColor: "#b5a9ff",
        },
      ],
      externalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "GDP",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              // display only if larger than 1
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      internalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "GDP",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      contentExpandData: [
        {
          name: "Direct Posts",
          data: [10],
          pointWidth: 16,
          yAxis: 1,
        },
        {
          name: "Product reviews",
          data: [31],
          pointWidth: 16,
          yAxis: 1,
        },
        {
          name: "Influencer mentions",
          data: [63],
          pointWidth: 16,
          yAxis: 1,
        },
        {
          name: "Interviews",
          data: [20],
          pointWidth: 16,
          yAxis: 1,
        },
      ],
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 10, 21),
              x2: Date.UTC(2014, 11, 2),
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 2),
              x2: Date.UTC(2014, 11, 10),
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: Date.UTC(2014, 11, 10),
              x2: Date.UTC(2014, 11, 23),
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: Date.UTC(2014, 11, 23),
              x2: Date.UTC(2014, 11, 30),
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: false,
          },
        },
      ],
      multipleTitle: ["External", "Internal"],
    };
  },

  created() {
    this.selectedMarket = JSON.parse(sessionStorage.getItem("kpiMarket"));
  },
  methods: {
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    getDataBreakdown(data){
      console.log(data)
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
      console.log(this.breakDown, "this.breakDownthis.breakDownthis.breakDown");
    },
    getContentBreakdown(newVal) {
      this.contentBreakDown = newVal;
    },

    selectMarket(e) {
      var defaultOption = JSON.parse(sessionStorage.getItem("kpiMarket"));
      if (e === "Select") {
        if (e === this.selectedMarket) {
          return;
        }
        this.selectedMarket = defaultOption;
        return;
      }
      this.selectedMarket = e;
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  margin-top: 71px;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>
